import { render, staticRenderFns } from "./FissionPrize.vue?vue&type=template&id=96a7b572&scoped=true"
import script from "./FissionPrize.vue?vue&type=script&lang=ts"
export * from "./FissionPrize.vue?vue&type=script&lang=ts"
import style0 from "./FissionPrize.vue?vue&type=style&index=0&id=96a7b572&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96a7b572",
  null
  
)

export default component.exports