
import { Component, Mixins } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class TopBtn extends Mixins(Mixin) {
  /*
   * 去历史活动
   */
  goHistory(): void {
    this.$emit("goHistory");
  }

  /*
   * 去我的奖品
   */
  goMyPrize(): void {
    this.$emit("goMyPrize");
  }
}
