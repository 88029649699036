
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import PrizeOne from "./PrizeOne.vue";
import PrizeMore from "./PrizeMore.vue";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    PrizeOne,
    PrizeMore,
  },
})
export default class FissionPrize extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public prizeList!: Array<cms.ActivityPrizeDto>;
}
