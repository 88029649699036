
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class HelpLeaderboard extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "还没有活动",
  })
  public title!: string;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public helpList!: cms.ActivityInvitationInfoDto[];

  /*
   * 关闭
   */
  close(): void {
    this.$emit("close");
  }
}
