
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class HisroryItem extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public itemInfo!: cms.ActivityHistoryDto;
  /*
   *  去活动详情
   */
  goHistoryDetail(): void {
    this.$emit("goHistoryDetail");
  }
}
