
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import FissionActivityDemo from "@/views/LimitActivities/ActivityDemo/FissionActivityDemo.vue";

@Component({
  components: {
    FissionActivityDemo,
  },
})
export default class FissionActivity extends Mixins(Mixin) {
  mounted(): void {
    let RedEnvelope = this.$refs[
      "fission-activity-demo"
    ] as FissionActivityDemo;
    if (RedEnvelope) {
      RedEnvelope.updateData();
    }
  }
}
